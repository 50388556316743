import styled from "styled-components";
import { Colors, Rem } from "../../commons/Theme";

export const StyledProgressIndicator = styled.div`
  height: ${Rem(10)};
  width: 100%;
  border-radius: ${Rem(5)};
  background: ${Colors.grayLight};
`;

export const StyledProgressIndicatorValue = styled(StyledProgressIndicator)`
  width: 0;
  background: ${Colors.yellow};
`;
