import React from "react";
import { 
  StyledProgressIndicator,
  StyledProgressIndicatorValue,
} from "./style.jsx";

export default ({
  value,
  goal,
}) => {
  return (
    <StyledProgressIndicator>
      <StyledProgressIndicatorValue style={{ width: `${value/goal*100}%` }}/>
    </StyledProgressIndicator>
  );
};
